import React from "react";
import {
  BsInstagram,
  BsArrowLeftShort,
  BsArrowRightShort,
} from "react-icons/bs";

import { SubHeading } from "../../components";
import { images } from "../../constants";
import "./Gallery.css";

const Gallery = () => {
  const scrollRef = React.useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === "left") {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };

  return (
    <div className="app__gallery flex__center">
      <div className="app__gallery-content">
        <SubHeading title="Instagram" />
        <h1 className="headtext__cormorant">Fotoğraf Galerisi</h1>
        <p
          className="p__opensans"
          style={{ color: "#AAAAAA", marginTop: "2rem" }}
        >
          En sevdiğiniz ürünlerimizin ve müşteri hikayelerimizin özel anlarını
          sizinle paylaşıyoruz. <br /> Instagram'da bizi takip ederek bu güzel
          anlara tanık olabilirsiniz!
        </p>
        <a
          href="https://www.instagram.com/cankayakasaprestaurant/"
          target="_blank"
        >
          <button type="button" className="custom__button">
            Instagram Hesabımız
          </button>
        </a>
      </div>
      <div className="app__gallery-images">
        <div className="app__gallery-images_container" ref={scrollRef}>
          {[images.üc, images.dort, images.bir, images.iki].map(
            (image, index) => (
              <div
                className="app__gallery-images_card flex__center"
                key={`gallery_image-${index + 1}`}
              >
                <a
                  href="https://www.instagram.com/cankayakasaprestaurant/"
                  target="_blank"
                >
                  <img src={image} alt="gallery_image" />
                </a>
                <BsInstagram className="gallery__image-icon" />
              </div>
            )
          )}
        </div>
        <div className="app__gallery-images_arrows">
          <BsArrowLeftShort
            className="gallery__arrow-icon"
            onClick={() => scroll("left")}
          />
          <BsArrowRightShort
            className="gallery__arrow-icon"
            onClick={() => scroll("right")}
          />
        </div>
      </div>
    </div>
  );
};

export default Gallery;

import images from "./images";

const wines = [
  {
    title: "Kasap Köfte",
    price: "750₺",
    tags: "| Kilogram Fiyatı",
  },
  {
    title: "Kuzu Pirzola",
    price: "1050₺",
    tags: "| Kilogram Fiyatı",
  },
  {
    title: "Kuzu Şiş",
    price: "1000₺",
    tags: "| Kilogram Fiyatı",
  },
  {
    title: "Kuzu Külbastı",
    price: "1000₺",
    tags: "| Kilogram Fiyatı",
  },
  {
    title: "Kuzu Ciğer",
    price: "750₺",
    tags: "| Kilogram Fiyatı",
  },
];

const cocktails = [
  {
    title: "Çoban Salata",
    price: "55₺",
  },
  {
    title: "Kaşık Salata",
    price: "60₺",
  },
  ,
  {
    title: "Gavurdağ Salata",
    price: "70₺",
  },
  ,
  {
    title: "Domates Salatası",
    price: "70₺",
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: "Gurmelere Özel",
    subtitle: "Sadece doymak için değil, damak zevki için de.",
  },
  {
    imgUrl: images.award01,
    title: "Parlayan Yıldız",
    subtitle:
      "Yükselişte olan restoranımız, sizleri keyifli bir yemeğe davet ediyor.",
  },
  {
    imgUrl: images.award05,
    title: "A+ Misafirperver",
    subtitle:
      "Misafirlerimizi en iyi şekilde ağırlamak bizim için bir önceliktir.",
  },
  {
    imgUrl: images.award03,
    title: "Üstün Şef Hizmeti",
    subtitle: "Şefin ustalığı, yemeğin damak zevkini bir başka boyuta taşıyor.",
  },
];

export default { wines, cocktails, awards };

import React from "react";
import "./AboutUs.css";
import Harf from "../../assets/cc.png";
import { images } from "../../constants";

const AboutUs = () => (
  <div
    className="app__aboutus app__bg flex__center section__padding"
    id="about"
  >
    <div className="app__aboutus-overlay flex__center">
      <img src={Harf} alt="g letter" />
    </div>

    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">Misyonumuz</h1>
        <img src={images.spoon} alt="spoon" className="spoon_img" />
        <p className="p__opensans">
          Çankaya Restaurant olarak misyonumuz, misafirlerimize en kaliteli et
          ürünlerini, mükemmel pişirme teknikleriyle sunarak eşsiz bir lezzet
          deneyimi yaşatmaktır. Taze, yerel ve organik ürünler kullanarak
          hazırladığımız yemeklerimizle, her bir misafirimizi memnun etmek için
          özenle çalışıyoruz. Amacımız, samimi ve sıcak bir ortamda, lezzet dolu
          anılar biriktirmenizi sağlamaktır.
        </p>
      </div>

      <div className="app__aboutus-content_knife flex__center">
        <img src={images.knife} alt="aboutus_knife" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Vizyonumuz</h1>
        <img src={images.spoon} alt="spoon" className="spoon_img" />
        <p className="p__opensans">
          Vizyonumuz, sadece bir et restoranı olarak değil, aynı zamanda
          gastronomi alanında bir referans noktası olarak tanınmak ve sektörde
          lider konuma ulaşmaktır. Yenilikçi yaklaşımlarımızla, dünya
          standartlarında hizmet sunarak, misafirlerimize sürekli olarak en
          iyisini ve en lezzetlisini sunmayı hedefliyoruz. Yerel topluluğumuza
          değer katarken, uluslararası düzeyde de tanınan bir marka olmayı
          amaçlıyoruz.
        </p>
      </div>
    </div>
  </div>
);

export default AboutUs;

import React from "react";

import { SubHeading } from "../../components";
import { images } from "../../constants";
import "./Chef.css";
import Imza from "../../assets/gokhan.png";

const Chef = () => (
  <div className="app__bg app__wrapper section__padding">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.chef} alt="chef_image" />
    </div>
    <div className="app__wrapper_info">
      <SubHeading title="Şef'in Sözleri" />
      <h1 className="headtext__cormorant">Merhaba</h1>

      <div className="app__chef-content">
        <div className="app__chef-content_quote">
          <img src={images.quote} alt="quote_image" />
          <p className="p__opensans">
            Merhaba. Ben Gökhan Şef, Çankaya Restaurant'ın baş şefiyim.
          </p>
        </div>
        <p className="p__opensans">
          {" "}
          Restoranımız, misafirlerimize en iyi gastronomi deneyimini sunmak
          amacıyla kurulmuştur. Mutfak ekibimizle birlikte her gün taze, yerel
          ve kaliteli malzemeler kullanarak, lezzetli ve yaratıcı yemekler
          hazırlamaktan gurur duyuyoruz. <br /> Misyonumuz, sadece bir yemek
          sunmak değil, aynı zamanda misafirlerimize unutulmaz bir deneyim
          yaşatmaktır. Bu yüzden ekibimizle birlikte her zaman kendimizi
          geliştirmeye ve yenilikler sunmaya devam ediyoruz. Sizleri
          restoranımızda ağırlamaktan büyük mutluluk duyacağız.
          <br />
          Lezzet dolu günler dilerim!
          <br />
          <br />
          Saygılarımla,
        </p>
      </div>

      <div className="app__chef-sign">
        <p>Gökhan Dönmez</p>
        <p className="p__opensans">Şef & Kurucu</p>
        <img src={Imza} alt="sign_image" />
      </div>
    </div>
  </div>
);

export default Chef;

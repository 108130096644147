import React from "react";

import { SubHeading } from "../../components";
import { images } from "../../constants";

const FindUs = () => (
  <div className="app__bg app__wrapper section__padding" id="contact">
    <div className="app__wrapper_info">
      <SubHeading title="İletişim" />
      <h1 className="headtext__cormorant" style={{ marginBottom: "3rem" }}>
        Ziyaret Edin
      </h1>
      <div className="app__wrapper-content">
        <p className="p__opensans">
          Çankaya Mahallesi, Şehit Uzman Çavuş <br /> Erdoğan Sönmez Caddesi
          No:7/A Kepez/Antalya
        </p>
        <p
          className="p__cormorant"
          style={{ color: "#DCCA87", margin: "2rem 0" }}
        >
          Açılış Saatlerimiz
        </p>
        <p className="p__opensans">Pzt - Cum: 10:00 - 23:00 </p>
        <p className="p__opensans">Cmt - Pzr: 10:00 - 23:00 </p>
      </div>
      <a
        href="https://www.google.com/maps/place/ÇANKAYA+KASAP+%26+RESTAURANT/@36.9542299,30.6816264,20.5z/data=!4m6!3m5!1s0x14c385fda344d873:0x981cccc79d3a5271!8m2!3d36.9543213!4d30.6814609!16s%2Fg%2F11y66h8gry?entry=ttu"
        target="_blank"
      >
        <button
          type="button"
          className="custom__button"
          style={{ marginTop: "2rem" }}
        >
          Bizi Ziyaret Et
        </button>
      </a>
    </div>

    <div className="app__wrapper_img">
      <img src={images.reste} alt="finus_img" />
    </div>
  </div>
);

export default FindUs;
